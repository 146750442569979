import React from "react"

import {
  SEO,
  Header,
  Footer,
  PrivacyContent,
} from "../components"

const PrivacyPage = () => (
  <>
    <SEO title="Medical Bureau Privacy" />
    <Header hideLinks={true}/>
    <PrivacyContent />
    <Footer />
  </>
)

export default PrivacyPage
